import { Typography } from '@mui/material';

const Tos = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>利用規約</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        この利用規約（以下，「本規約」といいます。）は，（以下，「運営」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用される方（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。 </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第1条（適用）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        1.本規約は，ユーザーと当グループとの間の本サービスの利用に関わる一切の関係に適用されるものとします。<br /> 2.当グループは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。<br />3.本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第2条（禁止事項）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。<br />1.法令または公序良俗に違反する行為<br />2.犯罪行為に関連する行為<br />3.本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為<br />4.当グループ，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br />5.本サービスによって得られた情報を商業的に利用する行為<br />6.当グループのサービスの運営を妨害するおそれのある行為<br />7.不正アクセスをし，またはこれを試みる行為<br />8.他のユーザーに関する個人情報等を収集または蓄積する行為<br />9.他のユーザーに関する個人情報等を収集または蓄積する行為<br />10.本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為<br />11.当グループのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br />12.その他，当グループが不適切と判断する行為</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第3条（本サービスの提供の停止等）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        [1]当グループは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />1.本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />2.地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br />3.コンピュータまたは通信回線等が事故により停止した場合<br />4.その他，当グループが本サービスの提供が困難と判断した場合<br />[2]当グループは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第4条（保証の否認および免責事項）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        1.当グループは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br />2.当グループは，本サービスに起因してユーザーに生じたあらゆる損害について、当グループの故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する当グループとユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。<br />3.前項ただし書に定める場合であっても，当グループは，当グループの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当グループまたはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません<br />4.当グループは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第5条（サービス内容の変更等）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>当グループは，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第6条（利用規約の変更）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>[1]当グループは以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。<br />1.本規約の変更がユーザーの一般の利益に適合するとき。<br />2.本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。<br />[2]当グループはユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第7条（個人情報の取扱い）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>当グループは，本サービスの利用によって取得する個人情報については，当グループ「プライバシーポリシー」に従い適切に取り扱うものとします。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第8条（権利義務の譲渡の禁止）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>ユーザーは，当グループの書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第9条（準拠法・裁判管轄）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>1.本規約の解釈にあたっては，日本法を準拠法とします。<br />2.本サービスに関して紛争が生じた場合には，当グループの本店所在地を管轄する裁判所を専属的合意管轄とします。</Typography>
      <Typography variant="body1" sx={{ mb: 2 }} textAlign="right">以上</Typography>
    </>
  );
};

export default Tos;
